<script setup lang="ts">
import type { FunctionalComponent } from "vue";
import { VIcon } from "vuetify/components/VIcon";

interface Props {
  icon: string | (new () => any) | FunctionalComponent;
  color?: string;
  start?: boolean; // previously `left`
  end?: boolean; // previously `right`
  size?: string | number; // previously `x-small, small, default, large, and x-large` as props
}

const props = withDefaults(defineProps<Props>(), {
  color: undefined,
  start: undefined,
  end: undefined,
  size: undefined,
});
</script>

<template>
  <VIcon v-bind="props" />
</template>
